<template>
    <div>
        <currentLocation :sibItem="sibItem"></currentLocation>
        <div class="screenWidth">
            <div class="title">
                {{ title }}
            </div>
            <div class="content">
                <div v-html="content"></div>
                <div class="kong" v-if="content == ''">
                    <img src="../assets/image/kong.png" alt="">
                    <div>No Data</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "Detali",
                    path: "",
                },
            ],
            content:'',
            title:""
        }
    },
    methods: {},
    mounted() { 
        let query = this.$route.query
        this.content = atob(query.content)
        this.title = query.title
    },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped>
.screenWidth{
    padding-bottom: 100px;
}
.title{
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    padding: 50px 150px;
    word-break: break-word !important; 
}
.content{
    width: 100%;
    min-height:500px;
    position: relative;
}
</style>